import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faPersonDigging, faGear} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';

function App() {
  
  const userDatas = localStorage.getItem('userAuth');
      return (
        <div className="App">
          <header className="App-header">
          <nav className="navbar navbar-expand-md fixed-bottom bg-light d-none">
            <div className="container-fluid">
              <div className="navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/"><FontAwesomeIcon icon={faHouse} /></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/visite-chantier"><FontAwesomeIcon icon={faPersonDigging} /></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/reglages"><FontAwesomeIcon icon={faGear} /></a>
                  </li>
                </ul>
              </div>  
            </div>
          </nav>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                
              </Routes>
            </BrowserRouter>
          </header>
        </div>
      );
    
}



export default App;
